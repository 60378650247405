import React, { useState, useEffect } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import axios from 'axios';
import _ from 'lodash';

import raw from './sheets.json';
import { COLOR } from '../constants';
import { getTheme } from '../utility/theme';
import Layout from '../components/Layout';
import { CsvRow } from '../components/Csv/Row';
import { Button } from '@material-ui/core';
import styled from '@emotion/styled';

const muiTheme = (userTheme: string) =>
  createMuiTheme({
    palette: {
      type: userTheme === 'dark' ? 'dark' : 'light',
      primary: {
        main: COLOR.PRIMARY,
      },
      text: {
        primary: userTheme === 'dark' ? '#ffffff' : '#333333',
      },
    },
  });

type TagProps = {
  selected: boolean;
}
const Tag = styled.div<TagProps>`
  cursor: pointer;
  font-size: 0.8rem;
  font-style: italic;
  background-color: ${props => props.selected ? '#ff6961' : '#777'};
  padding: 2px 6px;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 4px;
`;
const Tags = styled.div`
  display: inline-flex;
  padding: 0;
  flex-wrap: wrap;
`;


const processRow = (row) => {
  const question = row[0];
  const answer = row[1];
  const passRate = row[2];
  const type = row[3];
  const tagColumns = row.slice(4);
  const allTags = tagColumns.map(col => {
    const arr = col.split(', ');
    return arr;
  });
  const tags = _.compact(_.uniq(_.flatten(allTags)));
  return {
    question,
    answer,
    passRate,
    type,
    tags,
  }
};

const processSheets = (sheets) => {
  const values = sheets.map(s => s.values);
  const flat = _.flatten(values);

  const processedRows = flat.map(processRow);

  const allTags = processedRows.map(r => r.tags);
  const uniqueTags = _.uniq(_.flatten(allTags));

  return {
    data: processedRows,
    uniqueTags,
  };

}
const Spacer = styled.div`
  margin-bottom: 2rem;
`;

const SPREADSHEET = '12bVHSZT2Qc5Lslf5RXL2xealjZTHN3oQ1r4IeEsku5s';
const SAQ_SHEETS = [
  '2021.1',
  '2020.2',
];
const RANGES = SAQ_SHEETS.map(s => 'ranges=' + s + '!A2:I').join('&');
const KEY = process.env.GATSBY_GOOGLE_SHEETS_KEY;

function handleKeyPress() {
  useEffect(() => {
  }, []);
}
export default function Page() {
  const userTheme = getTheme();
  const theme = muiTheme(userTheme);

  const [allRows, setAllRows] = useState([['','']]);
  const [allTags, setAllTags] = useState([]);
  const [displayRows, setDisplayRows] = useState([['','']]);
  const [clicks, setClicks] = useState(0);
  const [currentTag, setCurrentTag] = useState('All');

  const incrementClicks = () => {
    setClicks(clicks + 1);
  }

  useEffect(() => {
    async function getData() {
      const URL = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET}/values:batchGet?${RANGES}&key=${KEY}`;
      const result = await axios.get(URL).catch(console.log);
      const resultValues = result && result.data.valueRanges;
      const processed = processSheets(resultValues);

      const allRowsData = processed.data.map(d => [d.question, d.answer, d.tags]);
      const shuffled = allRowsData.sort(() => Math.random() - 0.5);
      setAllRows(shuffled);
      setDisplayRows(shuffled);
      setAllTags(processed.uniqueTags);
    }
    getData();

    function onKeyPress(e) {
      console.log('e:', e);
      if (e.key === 'e') {
        
      }
    }
    window.addEventListener('keydown', onKeyPress);
    return () => window.removeEventListener('keydown', onKeyPress);
  },[]);

  const shuffle = () => {
    const shuffled = allRows.sort(() => Math.random() - 0.5);
    setDisplayRows(shuffled);
    setClicks(0);
  };

  const handleTagClick = (tag) => () => {
    const selected = allRows.filter(row => {
      return row[2].includes(tag);
    });
    setDisplayRows(selected);
    setCurrentTag(tag);
  };
  const handleAllTagClick = () => {
    setDisplayRows(allRows);
    setCurrentTag('All');
  };



  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <h1>SAQs</h1>

        <Spacer />
        <Button type="button" color="primary" variant="contained" onClick={shuffle}>
            Shuffle
        </Button>
        <Spacer />

        {
          <Tags>
            <Tag key="all" onClick={handleAllTagClick} selected={currentTag === "All"}>All</Tag>
            {allTags.map((t, i) => {
            const key = t + i;
            return (<Tag key={key} onClick={handleTagClick(t)} selected={t === currentTag}>{t}</Tag>)
            })}

          </Tags>
        }

        <Spacer />

        {displayRows.map((d, i) => {
          const key = i + d[0].slice(0, 50);
          return (<CsvRow data={d} key={key} clicks={clicks} incrementClicks={incrementClicks} />)
        })}

      </Layout>
    </ThemeProvider>
  )
}
